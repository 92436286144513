import { useMatches } from '@remix-run/react'
import { useMemo } from 'react'

// Define a generic type for the expected structure of 'data'.
// You can add more specific typings based on your actual data structure.
type DataWithKey<T> = {
  [key: string]: T
}

/**
 * This hook searches for specific data on all loaders using useMatches.
 * @param {string} key The data key
 * @param {string} pathname The route which includes this pathname (optional)
 * @returns {T | undefined} The data or undefined
 */
export function useMatchesData<T>(key: string, pathname = ''): T {
  const matchingRoutes = useMatches()

  const route = useMemo(() => {
    return matchingRoutes.find((route) => {
      const data = route.data as DataWithKey<T> | undefined
      return data?.[key] !== undefined && route.pathname.includes(pathname)
    })
  }, [matchingRoutes, key, pathname])

  const data = route?.data as DataWithKey<T>
  return data?.[key]
}
